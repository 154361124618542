<template>
  <div class="index">
    <section class="banner-left">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="banner-content">
              <div class="subheading"></div>
              <h1>平台介绍</h1>
              <p>
                本实验平台致力于构建一个前沿金融科技教育生态系统。
                通过引入智能体助教并应用RAG技术，整合《信用评分应用》
                等专业书籍的核心知识，提供智能体互动提问、深入学习信
                用评分理论的互动学习环境。结合金融业务实际发布系列比赛
                ，激发学生的创新潜力和提升解决实际问题的能力。
              </p>
              <p>
                本实验平台结合人工智能技术，提供从理论学习到实景应用的个
                性化学习路径，进一步激发高校学子的创新活力，增加金融科技
                人才储备，助力成都建设打造国内一流、国际知名的金融科技创新中心。
              </p>
              <div class="btn btn-main" @click="ParticipateCompetition">Participate in the competition</div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="banner-img">
              <!-- <img src="../assets/decorate/hero-5-img.png" alt="" /> -->
              <img src="../assets/decorate/bannerRight.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="counter-wrap">
      <div class="container">
        <div class="row">
          <div class="counter-inner">
            <div class="row">
              <div class="col-lg-3">
                <div class="counter-item">
                  <div class="ti-desktop">
                    <img src="../assets/imgs/uesrNumber.png" alt="" />
                  </div>
                  <div class="count">
                    <dv-digital-flop
                      :config="config"
                      class="counter"
                      style="width: 70%; height: 100%"
                    />
                  </div>
                  <p>平台人数</p>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="counter-item">
                  <div class="ti-desktop">
                    <img
                      style="
                        width: 68px;
                        height: 68px;
                        margin-top: -8px;
                        margin-left: -8px;
                      "
                      src="../assets/imgs/collegesuniversities.png"
                      alt=""
                    />
                  </div>
                  <div class="count">
                    <dv-digital-flop
                      :config="DomesticUniversities"
                      class="counter"
                      style="width: 70%; height: 100%"
                    />
                  </div>
                  <p>国内高校</p>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="counter-item">
                  <div class="ti-desktop">
                    <img src="../assets/imgs/NumberPlatforms.png" alt="" />
                  </div>
                  <div class="count">
                    <dv-digital-flop
                      :config="NumberMatches"
                      class="counter"
                      style="width: 70%; height: 100%"
                    />
                  </div>
                  <p>比赛场数</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="about-img">
              <img class="img-fluid" src="../assets/decorate/2-2.png" alt="" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="section-heading">
              <div class="subheading">智能体助教，答疑新体验</div>
              <h3 style="font-size:15px">
                本平台利用人工智能技术，整合《信用评分应用》等教学专著和学习资源，开发了两名智能体助教，为学生提供个性化、全时性、反馈性的学习支持和指导，共同探讨信用评分、消费信用和财经管理等相关知识。
              </h3>
            </div>
            <div class="about-content">
              <div class="about-text-block">
                <div class="bi-film">
                  <img src="https://iscoring.creditscoring.cn/iscoring/img/gpt_man.581372b9.png" alt="" />
                </div>
                <h4>李老师</h4>
                <p>李老师专注于金融学领域的教学与研究，尤其是在信用评分和消费信贷方面有着较深的研究。关于信用评分教材和消费信用模型可以向他提问。</p>
              </div>
              <div class="about-text-block">
                <div class="bi-film">
                  <img src="https://iscoring.creditscoring.cn/iscoring/img/gpt_female.5609f5e1.png" alt="" />
                </div>
                <h4>张老师</h4>
                <p>张老师专注于金融学领域的教学与研究，尤其是在财经管理方面有着较深的研究。关于财经管理可以向她提问。</p>
              </div>
              <div class="btn" @click="GoConsult">Go consult</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="feature-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="feature-item">
              <div class="feature-icon">
                <img src="../assets/decorate/CreditScore.png" alt="" />
              </div>
              <div class="feature-text">
                <h4>比赛列表</h4>
                <p>
                    比赛列表包含赛事发布，比赛规则，比赛进度以及奖励设定等事宜。比赛形式全面升级，涵盖算法赛、挑战赛、应用赛和校园赛等综合赛制架构。
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="feature-item">
              <div class="feature-icon">
                <img src="../assets/decorate/EducationTeaching.png" alt="" />
              </div>
              <div class="feature-text">
                <h4>智能助教</h4>
                <p>
                    智能助教包含两名智能体助教，解答信用评分、消费信用和财经管理等专业领域的疑问，提供个性化、全时性、反馈性的学习支持和指导。
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="feature-item">
              <div class="feature-icon">
                <img src="../assets/decorate/AIIntelligence.png" alt="" />
              </div>
              <div class="feature-text">
                <h4>实验空间</h4>
                <p>
                    实验空间内部署了云端代码开发环境，是金融科技系列挑战赛的重要实践空间，为参赛者提供全面而灵活的编程和数据分析平台。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="category-section">
      <div class="container">
        <!-- 标题 -->
        <div class="row" id="ParticipateCompeti">
          <div class="col-lg-6">
            <div class="section-heading">
              <span class="subheading"> The first 8 matches </span>
              <h3>比赛列表</h3>
              <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipisicin gelit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3" v-for="(item, index) in MatchListAll" :key="index">
            <div class="course-category">
              <div class="course-category-background"></div>
              <div class="course-category-Mask"></div>
              <div class="CompetitionStatus">
                {{CalculateGameStatus(
                      item.registration_time,
                      item.start_time,
                      item.en_time,
                      item.challenge_status)
                }}
              </div>
              <!-- <div class="category-icon">
                <i class="el-icon-trophy"></i>
              </div> -->
              <h4>{{ item.challenge_title }}</h4>
              <p>报名开始时间：{{ item.registration_time }}</p>
              <p>比赛开始时间：{{ item.start_time }}</p>
              <p>比赛结束时间：{{ item.en_time }}</p>
              
              <div class="matchHover">
                <div class="shade_wrap" v-if="item.challenge_status == 0">
                  <div class="dd_hover_shade">
                    <p>
                      {{
                        new Date(getNowDate()) > new Date(item.registration_time) &&
                        new Date(item.start_time) > new Date(getNowDate())
                          ? `比赛将于${item.start_time}开始`
                          : `比赛将于${item.en_time}结束`
                      }}
                    </p>
                    <button @click="RaceJump(item)">
                      {{
                        new Date(getNowDate()) > new Date(item.registration_time) &&
                        new Date(item.start_time) > new Date(getNowDate())
                          ? "立即报名"
                          : "立即参与"
                      }}
                    </button>
                  </div>
                </div>
                <div class="shade_wrap" v-if="item.challenge_status != 0">
                  <div class="dd_hover_shade">
                    <p>
                      {{
                        item.challenge_status == -1 ? "比赛已暂停" : "比赛已结束"
                      }}
                    </p>
                    <button @click="RaceJump(item)">参看比赛</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="text-center">
              <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p> -->
              <div class="course-btn" @click="JumpAll">All Competition information</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="video-section" v-if="false">
      <div class="video-block-container"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="section-heading">
              <span class="subheading"> Working Process </span>
              <h3>Watch video to know more about us</h3>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="video-icon">
              <i class="el-icon-video-play"></i>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="course-grid" v-if="false">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-7">
            <div class="section-heading center-heading">
              <span class="subheading">Top Trending Courses</span>
              <h3>Over 200+ New Online Courses</h3>
            </div>
          </div>
        </div>
        <div class="text-center">
          <ul class="course-filter">
            <template v-for="(item,index) in gridList">
              <li :key="index" @click="gridIndex = index"><div :class="{'active':index == gridIndex}"> {{item}}</div></li>
            </template>
          </ul>
        </div>
        <div class="row course-gallery">
          <div class="course-item col-lg-4" v-for="item in 3">
            <div class="course-block">
              <div class="course-img">
                <div class="img-fluid">
                  <img src="../assets/decorate/course3.jpg" alt="">
                  <span class="course-label">Expert</span>
                </div>
              </div>
              <div class="course-content">
                <div class="course-price">
                  $100
                  <span class="del">$180</span>
                </div>
                <h4>React – The Complete Guide (React Router)</h4>
                <div class="rating">
                  <el-rate :v-model="5"></el-rate>
                </div>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis, alias.</p>
                <div class="course-footer">
                  <div class="course-meta">
                    <span class="course-student">
                      <i class="el-icon-s-custom"></i>
                      340
                    </span>
                    <span class="course-duration">
                      <i class="el-icon-s-flag"></i>
                      82 Lessons
                    </span>
                  </div>
                  <div class="buy-btn">
                    <a href="">Details</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <hr v-if="false">

    <section class="testimonial" v-if="false">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="section-heading">
              <div class="subheading">
                Testimonials
              </div>
              <h3>Learn New Skills to Go Ahead for Your Career</h3>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="testimonials-slides-2">
              <div class="owl-stage-outer">
                <div class="owl-stage">
                  <div class="owl-item" v-for="item in 4">
                    <div class="review-item">
                      <div class="client-info">
                        <i class="bi-quote el-icon-collection-tag"></i>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni eius autem aliquid pariatur rerum. Deserunt, praesentium.
                             Adipisci, voluptates nihil debitis</p>
                        <el-rate
                          class="rating"
                          :v-model="5"
                          disabled
                          show-score
                          text-color="#FF1949">
                        </el-rate>
                      </div>
                      <div class="client-desc">
                        <div class="client-img">
                          <img src="../assets/decorate/team-2.jpg" alt="">
                        </div>
                        <div class="client-text">
                          <h4>John Doe</h4>
                          <span>Developer</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="owl-nav">
                <div class="owl-prev">
                  <i class="el-icon-arrow-left"></i>
                </div>
                <div class="owl-next">
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="team" v-if="false">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="section-heading">
              <span class="subheading">
                Best Expert Trainer
              </span>
              <h3>Our Professional trainer</h3>
            </div>
          </div>
          <div class="col-lg-6" style="display: flex;align-items: center;justify-content: end;">
            <div class="course-btn">
              <div class="btn-main">
                Join With us
              </div>
            </div>
          </div>
        </div>
        <el-carousel height="400px" indicator-position="outside">
          <el-carousel-item v-for="item in 1" :key="item" style="display: flex;justify-content: space-around;">
            <div class="owl-item-carousel" v-for="item in 4">
              <div class="team-item">
                <img class="img-fluid" src="../assets/decorate/team-1.jpg" alt="">
                <div class="team-info">
                  <h4>Tanvir Hasan</h4>
                  <p>Market Researcher</p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </section>

    <section class="blog" v-if="false">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="section-heading">
              <span class="subheading">Blog News</span>
              <h3>Latest Blog News</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicin gelit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4" v-for="item in 3">
            <div class="blog-item">
              <img class="img-fluid" src="../assets/decorate/course3.jpg" alt="">
              <div class="blog-content">
                <div class="entry-meta">
                  <span>
                    <i class="el-icon-date"></i>
                    May 19, 2020
                  </span>
                  <span>
                    <i class="el-icon-s-custom"></i>
                    May 19, 2020
                  </span>
                </div>
                <h2>Powerful tips to grow business manner</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicin gelit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                <div class="btn-main">Read More</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="banner" v-if="false"></div>
    <div class="wrap card_wrap" v-if="false">
      <div
        class="decorate_element_1"
        :style="{ left: decorate_element_1 + 'px' }"
      >
        <!-- <img src="../assets/decorate/bf6e815e789a0db5b0d95e04854a18b2d9c4e9d251e17-dBAqSB_fw1200.png" alt=""> -->
        <img
          src="../assets/decorate/ebc333f6741246d158baa2f6878d3326d45e688522207-0K6Lik_fw1200.png"
          alt=""
        />
        <!-- <img src="../assets/decorate/3f34b24b3875f92dcec18e817da4b3b73afdfdb111c8e-hxkofJ_fw1200.png" alt=""> -->
      </div>
      <div
        class="decorate_element_2"
        :style="{ right: decorate_element_2 + 'px' }"
      >
        <img
          src="../assets/decorate/49dadc8605e1c5a061e1d1fb9ebf1e6f411ac18412750-aHTMKn_fw1200.png"
          alt=""
        />
      </div>
      <div class="PlatformIntroduction">
        <div class="PlatformIntroduction_title">
          <dv-decoration-7>平台介绍</dv-decoration-7>
        </div>

        <div class="NewIntroduce">
          <div class="col-lg-4">
            <div class="service-item text-center fade-in-bottom">
              <div class="service-icon">
                <img src="../assets/decorate/AIIntelligence.png" alt="" />
              </div>
              <div class="service-content">
                <h3 class="title">
                  <a href="">AI智能</a>
                </h3>
                <p>
                  一个关于研究、开发用于模拟、延伸和扩展人的智能的理论、方法、技术及应用系统的一门新的技术科学平台
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="service-item text-center fade-in-bottom">
              <div class="service-icon">
                <img src="../assets/decorate/EducationTeaching.png" alt="" />
              </div>
              <div class="service-content">
                <h3 class="title">
                  <a href="">教育教学</a>
                </h3>
                <p>
                  一个让大学生能够触摸金融科技行业前沿课题，学以致用，培养解决实际问题的综合能力和知识应用能力的教育教学平台
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="service-item text-center fade-in-bottom">
              <div class="service-icon">
                <img src="../assets/decorate/CreditScore.png" alt="" />
              </div>
              <div class="service-content">
                <h3 class="title">
                  <a href="">信用评分</a>
                </h3>
                <p>
                  一个关于风险管理理论和应用、数学和统计方法、数据和信息来源、建模和开发过程、实施和使用、信用风险管理周期、监管体系和制度平台
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="PlatformIntroduction_describe" v-if="false">
          <span>
            使大学生能够触摸金融科技行业前沿课题，学以致用，培养解决实际问题的综
            合能力和知识应用能力，增加
            金融科技人才储备，推动构建“成都特色、全国影响
            、国内示范、国际同步”的金融科技创新示范体系，助力成都金融科技建立在中西部
            地区领先、全国一流的地位
          </span>
        </div>
        <div class="PlatformCategory">
          <div class="Category_item left_shifting">
            <div class="CategoryImg">
              <img
                style="width: 80%; height: 80%"
                src="../assets/imgs/uesrNumber.png"
                alt=""
              />
            </div>
            <div class="CategoryTxt">
              <!-- <div class="MaintTitle">
				2000+
			  </div> -->
              <dv-digital-flop :config="config" class="MaintTitle" />
              <div class="Subtitle">平台人数</div>
            </div>
          </div>
          <div class="Category_item right_shifting">
            <div class="CategoryImg">
              <img src="../assets/imgs/collegesuniversities.png" alt="" />
            </div>
            <div class="CategoryTxt">
              <!-- <div class="MaintTitle">
				189
			  </div> -->
              <dv-digital-flop
                :config="DomesticUniversities"
                class="MaintTitle"
              />
              <div class="Subtitle">国内高校</div>
            </div>
          </div>
          <div class="Category_item left_shifting">
            <div class="CategoryImg">
              <img src="../assets/imgs/NumberPlatforms.png" alt="" />
            </div>
            <div class="CategoryTxt">
              <!-- <div class="MaintTitle">
				9
			  </div> -->
              <dv-digital-flop :config="NumberMatches" class="MaintTitle" />
              <div class="Subtitle">比赛场数</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 适合人群接受 -->
      <div class="SuitablePopulation">
        <div class="SuitablePopulation_title">
          <dv-decoration-7>适合人群</dv-decoration-7>
        </div>
        <div class="SuitablePopulation_list">
          <div class="SuitablePopulation_list_item">
            <div class="list_item_img headSculpture_1"></div>
            <div class="list_item_txtbox">
              <span> 有Python/R或者其他编程基础的学生 </span>
            </div>
          </div>
          <div class="SuitablePopulation_list_item">
            <div class="list_item_img headSculpture_2"></div>
            <div class="list_item_txtbox">
              对数据处理、特征工程、建模算法有浓厚的兴趣
            </div>
          </div>
          <div class="SuitablePopulation_list_item">
            <div class="list_item_img headSculpture_1"></div>
            <div class="list_item_txtbox">想学习算法建模的学生</div>
          </div>
          <div class="SuitablePopulation_list_item">
            <div class="list_item_img headSculpture_2"></div>
            <div class="list_item_txtbox">
              对算法建模学生，实际不在局限于调参，急迫需要理解算法本身
            </div>
          </div>
          <div class="SuitablePopulation_list_item">
            <div class="list_item_img headSculpture_1"></div>
            <div class="list_item_txtbox">
              对于正在研究算法的学生，想弄清楚产业中的算法是如何落地
            </div>
          </div>
          <div class="SuitablePopulation_list_item">
            <div class="list_item_img headSculpture_2"></div>
            <div class="list_item_txtbox">
              正在就业大学生，想充实自己的简历，项目上展示算法建模能力
            </div>
          </div>
        </div>
      </div>
      <!-- 比赛推荐列表 -->
      <div class="box boxs">
        <div class="dd_title">
          <div class="dd_word">
            <dv-decoration-7>最新比赛</dv-decoration-7>
          </div>
        </div>
        <ul class="clip_card">
          <li
            class="cpt_type0"
            v-for="(item, index) in MatchListAll"
            :key="index"
            :name="(index + 1) % 4 == 0 ? '' : 'cptmarginRight'"
          >
            <div class="dd_cpt_card_wrap">
              <div class="dd_cpt_logo">
                <!-- <img :src="item.challenge_image_path" alt="" /> -->
                <img src="../assets/decorate/challenge_8.jpg" alt="" />
                <div
                  class="status_icon_g"
                  :style="
                    item.challenge_status == 0 ? 'color: white;' : 'color:red;'
                  "
                >
                  <!-- item.challenge_status == 0? "进行中": item.challenge_status == 1? "已结束": "已暂停" -->
                  {{
                    CalculateGameStatus(
                      item.registration_time,
                      item.start_time,
                      item.en_time,
                      item.challenge_status
                    )
                  }}
                </div>
              </div>
              <div class="cpt_card_wrap">
                <div class="dd_cpt_intro1">
                  <p class="dd_cpe_name">
                    {{ item.challenge_title }}
                  </p>
                </div>
                <div class="dd_cpt_intro2">
                  <div class="dd_cpt_time">
                    报名开始时间：{{ item.registration_time }}
                  </div>
                  <div class="dd_cpt_time">
                    比赛开始时间：{{ item.start_time }}
                  </div>
                  <div class="dd_cpt_time">
                    比赛结束时间：{{ item.en_time }}
                  </div>
                </div>
              </div>
            </div>
            <!-- 遮罩层 -->
            <div class="shade_wrap" v-if="item.challenge_status == 0">
              <div class="dd_hover_shade">
                <p>
                  {{
                    new Date(getNowDate()) > new Date(item.registration_time) &&
                    new Date(item.start_time) > new Date(getNowDate())
                      ? `比赛将于${item.start_time}开始`
                      : `比赛将于${item.en_time}结束`
                  }}
                </p>
                <button @click="RaceJump(item)">
                  {{
                    new Date(getNowDate()) > new Date(item.registration_time) &&
                    new Date(item.start_time) > new Date(getNowDate())
                      ? "立即报名"
                      : "立即参与"
                  }}
                </button>
              </div>
            </div>
            <div class="shade_wrap" v-if="item.challenge_status != 0">
              <div class="dd_hover_shade">
                <p>
                  {{
                    item.challenge_status == -1 ? "比赛已暂停" : "比赛已结束"
                  }}
                </p>
                <button @click="RaceJump(item)">参看比赛</button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="dd_more_cpt_btn"
        v-if="this.MatchListAll.length > 4"
        @click="JumpAll"
      >
        查看更多比赛
      </div>
    </div>
  </div>
</template>

<script>
import { MatchList, cal_quantity } from "../api/CompetitionList.js";
export default {
  name: "index",
  data() {
    return {
      imgArr: [],
      Params: {
        keywords: "",
        pageNum: 1,
        pageSize: 8,
      },
      MatchListAll: [],
      config: {
        number: [0],
        content: "{nt}+",
        style: {
          //这里可以修改默认样式
          fontSize: 36, //字体大小
          fill: "#FFF", //字体颜色
        },
      },
      DomesticUniversities: {
        number: [0],
        content: "{nt}所",
        style: {
          //这里可以修改默认样式
          fontSize: 36, //字体大小
          fill: "#FFF", //字体颜色
        },
      },
      NumberMatches: {
        number: [0],
        content: "{nt}次",
        style: {
          //这里可以修改默认样式
          fontSize: 36, //字体大小
          fill: "#FFF", //字体颜色
        },
      },
      screenWidth: null,
      decorate_element_1: 20,
      decorate_element_2: 20,
      gridList:['All','printing','Web','illustration','media','crafts'],
      gridIndex:0,
    };
  },
  mounted() {
    // 处理数据
    cal_quantity().then((res) => {
      // setTimeout(() => {
      this.power("config", res.user_count);
      this.power("DomesticUniversities", res.school_count);
      this.power("NumberMatches", res.match_count);
      // }, 300);
    });
    this.getData();
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  created() {},
  watch: {
    screenWidth: function (n, o) {
      if (n <= 1280) {
        this.decorate_element_1 = -240;
        this.decorate_element_2 = -200;
        return;
      } else if (n <= 1536) {
        this.decorate_element_1 = -120;
        this.decorate_element_2 = -100;
        return;
      } else if (n <= 1750) {
        this.decorate_element_1 = -60;
        this.decorate_element_2 = -60;
        return;
      } else {
        this.decorate_element_1 = 20;
        this.decorate_element_2 = 20;
      }
    },
  },
  methods: {
    power(target, n) {
      this[target].number[0] = Math.floor(1 * n);
      this[target] = { ...this[target] }; //对象解构，更新props
    },
    // 计算比赛状态
    CalculateGameStatus(
      registration_time,
      start_time,
      en_time,
      challenge_status
    ) {
      if (challenge_status == "-1") {
        return "已暂停";
      } else if (challenge_status == "1") {
        return "已结束";
      } else if (challenge_status == "0") {
        if (
          new Date(this.getNowDate()) > new Date(registration_time) &&
          new Date(this.getNowDate()) < new Date(start_time)
        ) {
          return "报名中";
        } else if (
          new Date(this.getNowDate()) > new Date(start_time) &&
          new Date(this.getNowDate()) < new Date(en_time)
        ) {
          return "进行中";
        } else {
          return "进行中";
        }
      }
    },
    // 格式化日对象
    getNowDate() {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear(); // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds(); //秒
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        sign2 +
        minutes +
        sign2 +
        seconds
      );
    },
    getData() {
      MatchList(this.Params).then((res) => {
        this.MatchListAll = res.data.list;
      });
    },
    RaceJump(item) {
      // if (item.challenge_status == 1) {
      // 	this.$message.error("当前比赛已结算，请下次开放时参加！");
      // 	return;
      // }
      this.$router.push({
        path: "/SingleItem",
        query: {
          id: item.challenge_id,
          // matchstate: item.challenge_status,
        },
      });
    },
    JumpAll() {
      this.$router.push("/CompetitionList");
    },
    GoConsult(){
      this.$router.push("/artificialIntelligence");
    },
    ParticipateCompetition(){
      document.getElementById('ParticipateCompeti').scrollIntoView({ behavior: 'smooth' })
    },
  },
};
</script>

<style lang='scss' scoped>
@import "./index.scss";
@media (max-width: 1400px) {
  .boxs,
  .SuitablePopulation,
  .PlatformIntroduction {
    width: 1920px;
  }
}

@media (min-width: 992px) {
  .boxs,
  .SuitablePopulation,
  .PlatformIntroduction {
    width: 1080px;
  }
}

.index {
  background-color: rgb(245, 250, 252);
  background-color: #fff;
  //   margin-bottom: 110px;
  height: auto;

  .banner {
    // position: absolute;
    // top: 80px;
    // left: 0;
    width: 1080px;
    width: 80%;
    margin: 0 auto;
    height: 290px;
    height: 380px;
    background: url("../assets/banner/shutterstock_168447536-1.jpg") no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: rgb(245, 250, 252);
    box-sizing: border-box;
    margin-top: 20px;
    border-radius: 20px;
  }

  .wrap {
    min-width: 1080px;
    width: 100%;

    .PlatformIntroduction {
      margin: 0 auto;
      // height: 560px;
      // height: 360px;

      .PlatformIntroduction_title {
        width: 100%;
        height: 100px;
        font-size: 26px;
        font-weight: 600;
        color: rgb(76, 72, 125);
        box-sizing: border-box;
        padding: 20px 0;
        z-index: 10;

        .dv-decoration-7 {
          width: 200px;
          height: 60px;
          margin: 0 auto;
        }
      }

      .NewIntroduce {
        // width: 1080px;
        height: auto;
        display: flex;
        margin: 0 auto;
        margin-bottom: 40px;
        .col-lg-4 {
          margin: 0 20px;
          .service-item {
            background-color: #ffffff;
            padding: 40px 40px 30px 40px;
            border-radius: 20px;
            box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);
            position: relative;
            margin-bottom: 30px;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            // margin: 0 20px;
            cursor: pointer;
            .service-icon {
              background-color: #ffffff;
              height: 100px;
              width: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.03));
              margin: 0 auto;
              border-radius: 50%;
              margin-bottom: 30px;
              img {
                width: 60px;
                height: 60px;
              }
            }
            .service-content {
              .title {
                font-size: 22px;
                font-weight: 600;
                margin-bottom: 20px;
              }
              a {
                color: inherit;
                outline: none;
                border: none;
                background: transparent;
                text-decoration: none;
              }
              a:hover {
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -ms-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
              }
              p {
                font-size: 16px;
                font-weight: var(--pb-fw-medium);
                color: var(--pb-color-text-body);
                margin-bottom: 15px;
                line-height: 25px;
              }
            }
          }
          .service-item:hover {
            margin-top: -10px;
          }
          .text-center {
            text-align: center !important;
          }
          .fade-in-bottom {
            -webkit-animation-name: fade-in-bottom;
            animation-name: fade-in-bottom;
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-fill-mode: both;
          }
        }
      }

      @keyframes fade-in-bottom {
        0% {
          -webkit-transform: translateY(50px);
          transform: translateY(50px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
          opacity: 1;
        }
      }

      .PlatformIntroduction_describe {
        margin: 0 auto;
        width: 880px;
        width: 80%;
        height: 120px;
        font-size: 16px;
        color: #333;
        padding: 0 16px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
        display: flex;
        align-items: center;
      }

      .PlatformCategory {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        // justify-content: space-around;
        // padding-left: 56px;
        .Category_item {
          width: 300px;
          width: 260px;
          height: 100px;
          display: flex;
          margin: 10px 0;
          cursor: pointer;
          .CategoryImg {
            width: 80px;
            height: 80px;
            margin-top: 25px;
            text-align: center;
            display: table-cell;
            vertical-align: middle;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .CategoryTxt {
            width: 160px;
            height: 100px;
            margin-left: -20px;

            .MaintTitle {
              width: 100%;
              height: 80px;
              line-height: 80px;
              font-size: 36px;
              font-weight: 600;
              padding-left: 20px;
              color: #2b67ee;
            }

            .Subtitle {
              width: 100%;
              height: 20px;
              font-size: 18px;
              line-height: 20px;
              text-align: center;
              color: #000;
            }
          }
        }

        // .left_shifting {
        //   margin-left: 30%;
        // }

        // .right_shifting {
        //   margin-left: 60%;
        // }
      }
    }

    .SuitablePopulation {
      margin: 0 auto;
      // width: 100%;
      height: 500px;
      position: relative;
      // background-color: aquamarine;
      .SuitablePopulation_title {
        width: 100%;
        height: 100px;
        font-size: 26px;
        font-weight: 600;
        color: rgb(76, 72, 125);
        box-sizing: border-box;
        padding: 20px 0;

        .dv-decoration-7 {
          width: 200px;
          height: 60px;
          margin: 0 auto;
        }
      }

      .SuitablePopulation_list {
        width: 100%;
        height: 400px;
        display: flex;
        // justify-content: space-around;
        justify-content: space-evenly;
        flex-wrap: wrap;

        .SuitablePopulation_list_item {
          width: 440px;
          height: 100px;
          display: flex;

          .list_item_img {
            width: 100px;
            height: 100px;
          }

          .headSculpture_1 {
            background: url("../assets/imgs/headSculpture_11.png") no-repeat;
            background-size: 80% 80%;
            background-position: 50% 50%;
          }

          .headSculpture_2 {
            background: url("../assets/imgs/headSculpture_22.png") no-repeat;
            background-size: 80% 80%;
            background-position: 50% 50%;
          }

          .list_item_txtbox {
            width: 340px;
            height: 66px;
            // line-height: 66px;
            font-size: 14px;
            color: #333;
            box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
            padding: 0 16px;
            margin-top: 17px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .SuitablePopulation::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: url("../assets/decorate/2c8424a5242bc7af6696817e52a8ad029507b2c2114ad-bs56Tu_fw1200.png")
        no-repeat;
      background-size: cover;
      background-position: 0 0;
      // transform: rotate(180deg);
    }

    .box {
      // width: 1080px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;

      .dd_title {
        // padding-top: 60px;
        // margin-top: 20px;
        // padding-bottom: 30px;
        width: 100%;
        height: 100px;

        .dd_word {
          width: 100%;
          height: 100px;
          font-size: 26px;
          font-weight: 600;
          color: rgb(76, 72, 125);
          box-sizing: border-box;
          padding: 20px 0;

          .dv-decoration-7 {
            width: 200px;
            height: 60px;
            margin: 0 auto;
          }
        }
      }

      .clip_card {
        max-height: 746px;
        max-width: 1080px;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0;

        // [name="cptmarginRight"] {
        //   margin-right: 20px;
        // }
        .cpt_type0 {
          width: 255px;
          height: 310px;
          height: 340px;
          list-style: none;
          cursor: pointer;
          position: relative;
          // margin-right: 0px;
          margin-bottom: 20px;
          box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);

          .dd_cpt_card_wrap {
            width: 100%;
            height: 100%;
            border: 1px solid #eeeeee;
            box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);

            .dd_cpt_logo {
              width: 100%;
              height: 154px;
              position: relative;

              img {
                display: block;
                width: 100%;
                height: 100%;
              }

              .status_icon_g {
                padding: 8px 6px;
                font-size: 12px;
                position: absolute;
                top: 0;
                right: 0;
                background: rgb(43, 103, 238);
                color: white;
              }
            }

            .cpt_card_wrap {
              box-sizing: border-box;

              .dd_cpt_intro1 {
                padding: 14px 0 0 16px;

                .dd_cpe_name {
                  font-size: 18px;
                  font-weight: 560;
                  color: #333;
                  margin-bottom: 10px;
                  width: 228px;
                  line-height: 20px;
                  height: 48px;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                }
              }

              .dd_cpt_intro2 {
                border-top: 1px solid #eeeeee;

                .dd_cpt_time {
                  font-size: 12px;
                  height: 12px;
                  color: #999;
                  margin-top: 10px;
                  margin-bottom: 16px;
                  margin-left: 16px;
                }
              }
            }
          }

          .shade_wrap {
            width: 254px;
            height: 310px;
            height: 340px;
            display: none;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            text-align: center;
            background: rgb(#000, 0.9);
            transition: opacity 2s;

            .dd_hover_shade {
              width: 100%;
              height: 100%;
              display: block;

              p {
                color: #fff;
                font-size: 16px;
                margin-top: 114px;
              }

              button {
                display: block;
                width: 125px;
                height: 38px;
                font-size: 17px;
                background: rgb(43, 103, 238);
                color: #fff;
                text-decoration: none;
                text-align: center;
                line-height: 38px;
                border-radius: 3px;
                margin: 50px auto;
                border: none;
                cursor: pointer;
                border: 0px;
                outline: none;
              }
            }
          }
        }

        .cpt_type0:hover .shade_wrap {
          display: block;
          transition: opacity 2s;
          opacity: 1;
        }
      }
    }

    .dd_more_cpt_btn {
      text-align: center;
      line-height: 44px;
      width: 200px;
      height: 44px;
      border: none;
      margin: 0 auto;
      display: block;
      background: rgb(43, 103, 238);
      color: white;
      font-size: 18px;
      outline: none;
      cursor: pointer;
    }
  }

  .card_wrap {
    position: relative;

    .decorate_element_1 {
      position: absolute;
      top: 70px;
      top: 300px;
      left: 20px;
      z-index: 9;
      transform: scale(0.7); // img{
      //   width: 580px;
      //   height: 500px;
      // }
    }

    .decorate_element_2 {
      position: absolute;
      top: 360px;
      // right: 20px;
      z-index: 9;
      transform: scale(0.7); // img{
      //   width: 580px;
      //   height: 500px;
      // }
    }
  }
}
</style>